import React from "react";
import { Redirect } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

import MetisMenu from "react-metismenu";
import RouterLink from "react-metismenu-router-link";

import logo from "assets/img/Outcome.png";
import { admContent, admContentCollapsed, admMobileContent } from "./adminView";
import {
  empContent,
  empContentCollapsed,
  empMobileContent
} from "./employeeView";

import "./LeftNav.css";

import {
  clnContent,
  clnContentCollapsed,
  clnMobileContent
} from "./clientView";

library.add(
  faChevronLeft,
  faTachometerAlt,
  faSitemap,
  faBuilding,
  faProjectDiagram,
  faChartLine,
  faUsers,
  faNewspaper
);
export default class LeftNav extends React.Component {
  constructor() {
    super();
    let role = localStorage.getItem("role");

    this.state = {
      navContent: [],
      call: false,
      loggedOut: false,
      role: role,
      // content: role === "ADMIN" ? admContent : empContent,
      // contentCollapsed:
      //   role === "ADMIN" ? admContentCollapsed : empContentCollapsed,
      // mobileContent: role === "ADMIN" ? admMobileContent : empMobileContent
      content: [],
      contentCollapsed: [],
      mobileContent: []
    };
  }

  componentDidMount() {
    // this.Role();
    /*if (!this.props.isMobile) {
      this.updateDepartmentList();
    }*/
  }

  logOut = () => {
    localStorage.removeItem("api_key");
    localStorage.removeItem("username");
    localStorage.removeItem("role");
    this.setState({ loggedOut: true });
  };

  Role = () => {
    if (this.state.role === "ADMIN") {
      this.setState({
        content: admContent,
        contentCollapsed: admContentCollapsed,
        mobileContent: admMobileContent
      });
    } else if (this.state.role === "EMPLOYEE") {
      this.setState({
        content: empContent,
        contentCollapsed: empContentCollapsed,
        mobileContent: empMobileContent
      });
    } else if (this.state.role === "CLIENT") {
      this.setState({
        content: clnContent,
        contentCollapsed: clnContentCollapsed,
        mobileContent: clnMobileContent
      });
    }
  };

  render() {
    if (this.state.loggedOut) {
      return <Redirect to="/" />;
    }

    let { fullWidth } = this.props;
    let btn_class = fullWidth ? "body-left" : "body-left";
    let sidebar_footer = fullWidth ? "sidebar-footer" : "sidebar-footer";
    let arrow_container = fullWidth
      ? "floating-arrow floating-arrow-expanded"
      : "floating-arrow floating-arrow-collapsed";

    let arrowClass = fullWidth ? "fa fa-angle-left" : "fa fa-angle-right";

    if (!this.props.isMobile) {
      if (this.state.navContent.length > 0) {
        let metisContent = fullWidth
          ? this.state.navContent
          : this.state.contentCollapsed;

        return (
          <div className={btn_class}>
            <div className="left-nav">
              { <div className="menu-logo-wrapper">
                <img src={logo} className="menu-logo" alt="logo" />
              </div> }
              <div
                className={arrow_container}
                onClick={this.props.handleFullscreen}
              >
                <i
                  style={{ height: "3%", padding: "3% 6.5%", color: "#f00" }}
                  className={arrowClass}
                />
              </div>
              <MetisMenu
                className="metis-menu-parent"
                classNameLink="metis-menu"
                iconNameStateVisible="angle-down"
                iconNameStateHidden="angle-left"
                content={metisContent}
                LinkComponent={RouterLink}
                activeLinkFromLocation
                activeLinkId={1}
              />
              <div className={sidebar_footer}>
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <a
                      className="metismenu-link metis-menu"
                      onClick={this.logOut}
                    >                      
                      {fullWidth ? "Logout" : null}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
      } else {
        let metisContent = fullWidth ? admContent : admContentCollapsed;
        return (
          <div className={btn_class}>
            <div className="left-nav">
              { <div className="menu-logo-wrapper">
                <img src={logo} className="menu-logo" alt="logo" />
              </div> }
              <div
                className={arrow_container}
                onClick={this.props.handleFullscreen}
              >
                <i
                  style={{ height: "3%", padding: "3% 6.5%", color: "#F00" }}
                  className={arrowClass}
                />
              </div>
              <MetisMenu
                className="metis-menu-parent"
                classNameLink="metis-menu"
                iconNameStateVisible="angle-down"
                iconNameStateHidden="angle-left"
                content={metisContent}
                LinkComponent={RouterLink}
                activeLinkFromLocation
              />
            </div>
            <div className={sidebar_footer}>
              <ul className="metismenu-container">
                <li className="metismenu-item">
                  <a
                    className="metismenu-link metis-menu"
                    onClick={this.logOut}
                  >
                    {fullWidth ? "Logout" : null}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        );
      }
    } else {
      return (
        <MetisMenu
          className="metis-menu-parent"
          classNameLink="metis-menu"
          iconNameStateVisible="angle-down"
          iconNameStateHidden="angle-left"
          content={admMobileContent}
          LinkComponent={RouterLink}
          activeLinkFromLocation
        />
      );
    }
  }
}
