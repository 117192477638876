import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./Home";
import Login from "./views/Login/Login";
import ResetPassword from "./views/ResetPassword/ResetPassword";

import "react-perfect-scrollbar/dist/css/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route path="/" exact={true} component={Login} />
          <Route path="/home" component={Home} />
          <Route path="/reset" component={ResetPassword} />
        </Switch>
      </div>
    );
  }
}

export default App;
