/**
 * @settings is to do conditional statements.
 *
 * @author
 */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
// Survey Pages
import "./CreateSurvey.css";

import Select from "react-select";

const styles = theme => ({
    root: {
        width: "100%"
    },
    backButton: {
        marginRight: theme.spacing.unit
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    },
    card: {
        minWidth: "90%"
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)"
    },
    title: {
        marginBottom: 16,
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
    stepIcon: {
        color: "#d15c17"
    },
    checked: {}
});

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validate: 0,
            condtioncount: [],
            conditions: [],
            drops: []
        };

        this.addCondtion = this.addCondtion.bind(this);
        this.deleteCondtion = this.deleteCondtion.bind(this);
        this.deleteSource = this.deleteSource.bind(this);
        this.addNewCondtions = this.addNewCondtions.bind(this);
        this.addMultiSource = this.addMultiSource.bind(this);
    }

    componentWillMount() {
        let conditions = this.props.oldconditions;
        for (var i = 0; i < this.props.oldconditions.length; i++) {
            let source = this.props.oldconditions[i].source;
            let sourceArray = [];

            if (!(source instanceof Array)) {
                sourceArray.push(source);
                conditions[i].source = sourceArray;
            }

        }
        this.setState({
            conditions: conditions,
            drops: this.props.drops
        });
    }

    /**
     * Add New Condition to Array
     */
    addCondtion() {
        let conditions = this.state.conditions;
        let cid = conditions ? conditions.length : 0;
        if (conditions.length === 0) {
            let source = {
                handler: "",
                state: ""
            };
            let sourceArr = [];
            sourceArr.push(source);

            conditions.push({
                condtion_id: cid,
                source: sourceArr,
                rule: "any",
                target: {
                    do: "",
                    handler: ""
                }
            });
        } else {

            let sourcehandlercheck = [];
            let sourcestatecheck = [];
            let sourcetargetcheck = [];
            let valuecheck = [];

            for (var i = 0; i < conditions.length; i++) {
                for (var j = 0; j < conditions[i].source.length; j++) {

                    if (conditions[i].source[j].state !== "empty" && conditions[i].source[j].state !== "filled") {
                        if (conditions[i].source[j].handler === "") {
                            sourcehandlercheck = conditions[i]
                        }
                        if (conditions[i].source[j].state === "") {
                            sourcestatecheck = conditions[i]
                        }
                        if (conditions[i].source[j].target === "") {
                            sourcetargetcheck = conditions[i]
                        }
                        if (conditions[i].source[j].match_option === "") {
                            valuecheck = conditions[i]
                        }
                        if (conditions[i].source[j].target === "value" && (conditions[i].source[j].match_value === "" || (!conditions[i].source[j].match_value))) {
                            valuecheck = conditions[i]
                        }
                        if (conditions[i].source[j].target === "field" && (conditions[i].source[j].matchid === "" || (!conditions[i].source[j].matchid))) {
                            valuecheck = conditions[i]
                        }
                    }
                }
            }

            let targetdocheck = conditions.filter((condition) => (
                condition.target.do === ""
            ))
            let targethandlercheck = conditions.filter((condition) => (
                condition.target.handler === ""
            ))


            if (sourcehandlercheck.length >= 1 || sourcestatecheck.length >= 1 || sourcetargetcheck.length >= 1 || targetdocheck.length >= 1 || targethandlercheck.length >= 1 || valuecheck.length >= 1) {
                this.setState({
                    validate: 1
                });
            } else {
                let source = {
                    handler: "",
                    state: ""
                };
                let sourceArr = [];
                sourceArr.push(source);

                conditions.push({
                    condtion_id: cid,
                    source: sourceArr,
                    rule: "any",
                    target: {
                        do: "",
                        handler: ""
                    }
                });

                this.setState({
                    validate: 0
                });
            }

        }
        this.setState(
            {
                conditions
            },
            this.props.getCondtions(this.state.conditions),
            this.props.autosave()
        );
    }

    addMultiSource = (index) => {
        const conditions = this.state.conditions;
        let source = {
            handler: "",
            state: ""
        };
        conditions[index].source.push(source);
        this.setState({ conditions }, this.props.getCondtions(this.state.conditions));
    };

    /**
     * Delete Condition from Array
     *
     * @param id
     */
    deleteCondtion(id) {

        let countadd = this.state.conditions;
        countadd.splice(id, 1);
        this.setState({ countadd }, this.props.autosave());
    }

    deleteSource(id, sourceId) {

        let countadd = this.state.conditions;
        countadd[id].source.splice(sourceId, 1);
        this.setState({ countadd }, this.props.autosave());
    }

    /**
     * Add new Parameter to the condition
     *
     * @param event
     * @param drop
     * @param label
     */
    addNewCondtions(event, drop, label, sourceIdx, temp) {

        if (label == "rule") {
            let conditionid = drop.condtion_id;
            let newcondtions = this.state.conditions;
            delete newcondtions[conditionid].error;
            let newdrops = this.state.drops.filter((drop, index) => {
                return drop.handler == event.target.value;
            });
            newcondtions[conditionid].rule = event.target.value;
            this.setState({ newcondtions, newdrops }, this.props.getCondtions(this.state.conditions), this.props.autosave());
        }
        else if (label == "source") {
            console.log("testtt1")
            console.log(event, drop, label, "ooooo", event.target.name, event.target.value)
            let value = event.target.value.split(".")[0];
            let type = event.target.value.split(".")[1];
            if (event.target.name === "handler" || event.target.name === "matchid") value = parseInt(value);
            let conditionid = drop.condtion_id;
            let newcondtions = this.state.conditions;
            if (newcondtions[conditionid].error) {
                delete newcondtions[conditionid].error;
            }
            let newdrops = this.state.drops.filter((ques, index) => {
                return ques.handler == event.target.value;
            });
            newcondtions[conditionid].source[sourceIdx][`${event.target.name}`] = value;
            if (event.target.name === "match_value") {
                newcondtions[conditionid].source[sourceIdx][`${event.target.name}`] = event.target.value;
            }

            if (event.target.name === "handler" && label === "source") {
                console.log("handler,source", newcondtions[conditionid].source[sourceIdx].matchid)
                newcondtions[conditionid][`${label}`][sourceIdx].source_type = type;
                if (newcondtions[conditionid].source[sourceIdx].matchid) {
                    delete newcondtions[conditionid].source[sourceIdx].matchid
                }
                if (newcondtions[conditionid].source[sourceIdx].target) {
                    delete newcondtions[conditionid].source[sourceIdx].target
                }
                if (newcondtions[conditionid].source[sourceIdx].match_value) {
                    delete newcondtions[conditionid].source[sourceIdx].match_value
                }
                if (newcondtions[conditionid].source[sourceIdx].match_option) {
                    delete newcondtions[conditionid].source[sourceIdx].match_option
                }
                if (newcondtions[conditionid].source[sourceIdx].state) {
                    newcondtions[conditionid].source[sourceIdx].state = ""
                }
            }

            if (event.target.value === "empty" || event.target.value === "filled") {
                console.log("emptyor filled", newcondtions[conditionid], newcondtions[conditionid].source[sourceIdx], "test")
                // newcondtions[conditionid][`${label}`][sourceIdx].source_type = type;
                console.log("type")
                if (newcondtions[conditionid].source[sourceIdx].matchid) {

                    delete newcondtions[conditionid].source[sourceIdx].matchid
                    console.log("ttttt111gk")
                }
                if (newcondtions[conditionid].source[sourceIdx].target) {
                    console.log("ttttt1112")
                    delete newcondtions[conditionid].source[sourceIdx].target
                    console.log("ttttt111222")
                }
                if (newcondtions[conditionid].source[sourceIdx].match_value) {
                    console.log("ttttt1113")
                    delete newcondtions[conditionid].source[sourceIdx].match_value
                    console.log("ttttt111333")
                }
                if (newcondtions[conditionid].source[sourceIdx].matchid) {
                    console.log("ttttt1114")
                    delete newcondtions[conditionid].source[sourceIdx].matchid
                    console.log("ttttt1114444")
                }
            }
            if (event.target.value !== "empty" && event.target.value !== "filled") {
                if (!newcondtions[conditionid].source[sourceIdx].target) {
                    newcondtions[conditionid].source[sourceIdx].target = ""
                }
            }

            if (event.target.name === "match_value") {
                console.log("matchvalue")
                if (newcondtions[conditionid].source[sourceIdx].matchid) {
                    delete newcondtions[conditionid].source[sourceIdx].matchid
                }
            }
            if (event.target.name === "matchid") {
                if (newcondtions[conditionid].source[sourceIdx].match_value) {
                    delete newcondtions[conditionid].source[sourceIdx].match_value
                }
            }
            if (temp === "table") {
                newcondtions[conditionid][`${label}`][sourceIdx].target = "value";
                newcondtions[conditionid][`${label}`][sourceIdx].match_option = "";
            }

            this.setState({ newcondtions, newdrops }, this.props.getCondtions(this.state.conditions), this.props.autosave());
        }
        else {
            console.log("do")
            let value = event.target.value.split(".")[0];
            let type = event.target.value.split(".")[1];
            if (event.target.name === "handler" || event.target.name === "matchid") value = parseInt(value);
            let conditionid = drop.condtion_id;
            let newcondtions = this.state.conditions;
            delete newcondtions[conditionid].error;
            let newdrops = this.state.drops.filter((drop, index) => {
                return drop.handler == event.target.value;
            });
            newcondtions[conditionid][`${label}`][`${event.target.name}`] = value;


            if (event.target.value === "hide_multiple" || event.target.value === "show_multiple") {
                delete newcondtions[conditionid].target["handler"];
            }
            if (event.target.value === "show" || event.target.value === "hide") {
                console.log("testdo2", newcondtions[conditionid], event.target.name)
                delete newcondtions[conditionid].target["multifield"];
            }
            if (event.target.value === "show" || event.target.value === "hide") {
                console.log("testdoooooo", newcondtions[conditionid])
                delete newcondtions[conditionid].target["hide"];
            }
            console.log("do emp filled", newcondtions[conditionid], newcondtions[conditionid].source[sourceIdx], "test", event.target.name)

            this.setState({ newcondtions, newdrops }, this.props.getCondtions(this.state.conditions), this.props.autosave());
        }
    }

    /**
     * Add Multi Value to Parameter like Show / Hide Multiple
     *
     * @param event
     * @param drop
     * @param property
     * @param label
     */

    addNewCondtionsMulti(event, drop, property, label) {
        let conditionid = drop.condtion_id;
        let newcondtions = this.state.conditions;
        newcondtions[conditionid][`${label}`][`${property}`] = event;
        this.setState({ newcondtions }, this.props.getCondtions(this.state.conditions), this.props.autosave());
    }


    errorcheck = e => {

        let errorcheck = this.state.drops.filter(sdrop => {
            return e.source.handler === sdrop.handler;
        });
        if (errorcheck) {
            errorcheck.length >= 1 && e.source.handler ? console.log("S") : console.log("N");
        }
    };

    choiceList = (conditions, source) => {
        let matchVal = source.match_value;
        let handler = source.handler;


        let selectedChoice = this.props.drops.filter(drop => {

            return handler === drop.handler;
        });

        if (selectedChoice[0] === undefined) {
            return
        } else if (selectedChoice[0].properties.multilevel == 1 && selectedChoice[0].properties.options) {
            return selectedChoice[0].properties.options.map((option, index) => (
                <optgroup label={option.label}>
                    {option.sublabel
                        ? option.sublabel.map((suboption, subindex) => (
                            <option selected={matchVal === suboption.sublabel ? "selected" : ""} value={suboption.sublabel}>
                                {suboption.sublabel}
                            </option>
                        ))
                        : ""}
                </optgroup>
            ));
        } else if (selectedChoice[0].properties.options) {
            return selectedChoice[0].properties.options.map((option, index) => (
                <option selected={matchVal === option.label ? "selected" : ""} value={option.label}>
                    {option.label}
                </option>
            ));
        }
    };

    scaleList = (conditions, source) => {
        let matchVal = parseInt(source.match_value);
        let handler = source.handler;


        let selectedChoice = this.props.drops.filter(drop => {

            return handler === drop.handler;
        });

        if (selectedChoice && selectedChoice[0] && selectedChoice[0].properties.scale_content) {
            return selectedChoice[0].properties.scale_content.map((option, index) => (
                <option selected={matchVal === option.value ? "selected" : ""} value={option.value}>
                    {option.value}
                </option>
            ));
        }
    };

    barcodeList = (conditions, source) => {
        let matchVal = source.match_value;
        let handler = source.handler;


        let selectedChoice = this.props.drops.filter(drop => {

            return handler === drop.handler;
        });

        if (selectedChoice[0].properties.barcode_ids) {
            return selectedChoice[0].properties.barcode_ids.map((option, index) => (
                <option selected={matchVal === option ? "selected" : ""} value={option}>
                    {option}
                </option>
            ));
        }
    };

    valueList = (conditions, source) => {
        let matchVal = source.match_value;
        let handler = source.handler;


        let selectedChoice = this.props.drops.filter(drop => {

            return handler === drop.handler;
        });


        if (selectedChoice && selectedChoice[0] && selectedChoice[0].properties.table_content.table_value) {
            return selectedChoice[0].properties.table_content.table_value.map((option, index) => (
                <option selected={matchVal === option.value ? "selected" : ""} value={option.value}>
                    {option.value}
                </option>
            ));
        }
    };

    optionsList = (conditions, source) => {
        let matchVal = source.match_option;
        let handler = source.handler;

        let selectedChoice = this.props.drops.filter(drop => {

            return handler === drop.handler;
        });

        if (selectedChoice && selectedChoice[0] && selectedChoice[0].properties.table_content.table_options) {
            return selectedChoice[0].properties.table_content.table_options.map((option, index) => (
                <option selected={matchVal === option.value ? "selected" : ""} value={option.value}>
                    {option.value}
                </option>
            ));
        }
    };

    tableList = (conditions, source) => {
        let handler = source.handler;

        let selectedChoice = this.props.drops.filter(drop => {
            return handler === drop.handler;
        });

        if (selectedChoice.length === 1) {
            if (selectedChoice[0].properties.scale_type) {
                return selectedChoice[0].properties.scale_type
            } else return null
        }
    }

    selectedElement = (conditions, source) => {
        let handler = source.handler;

        let selectedChoice = this.props.drops.filter(drop => {
            return handler === drop.handler;
        });
        if (selectedChoice.length === 1) {
            return selectedChoice[0]
        }
        else return { properties: {} }
    }

    doList = conditions => {
        let handlerArr = [];

        for (let i = 0; i < conditions.source.length; i++) {
            handlerArr.push(conditions.source[i].handler);
        }

        let newchoice = []
        let selectedChoice = this.props.drops.filter(drop => {
            return handlerArr.indexOf(drop.handler) <= -1;
        });
        let questionid = this.props.drops.filter(drop => {
            return handlerArr.indexOf(drop.handler) > -1;
        });
        newchoice = selectedChoice.filter(drop => {
            return questionid.length > 0 ? questionid[0].question_id != drop.question_id : false;
        });

        return newchoice
    }

    multiList = conditions => {
        let fields = []
        let handlerArr = [];

        for (let i = 0; i < conditions.source.length; i++) {
            handlerArr.push(conditions.source[i].handler);
        }

        let questionid = this.props.drops.filter(drop => {
            return handlerArr.indexOf(drop.handler) > -1;
        });
        let newchoice = this.props.drops.filter(drop => {
            return questionid.length > 0 ? questionid[0].question_id != drop.question_id : false;
        });
        let multifields = newchoice.map((field, index) => {
            return { label: field.label, value: field.handler };
        });
        fields = multifields.filter(drop => {
            return handlerArr.indexOf(drop.value) <= -1;
        });
        return fields
    }

    render() {
        // debugger
        return (
            <div className="success">
                <div className="dinamic_questions_parent">
                    <div className="dinamic_questions_wrap">
                        <div className="dinamic_quest_header">
                            <h3>Dynamic Question Section</h3>
                            <p>Change visibility of field(s) depending on `IF` State conditions.</p>
                        </div>
                        {this.state.conditions.map((drop, index) => (

                            <div key={index}>
                                <div className="dinamic_quest_body">
                                    {drop.error === 1 ? (
                                        <div className="error errordeletes">
                                            <i class="fa fa-times-circle" aria-hidden="true" /> One or more fields have been deleted which are required by this condition.
                                        </div>
                                    ) : (
                                            ""
                                        )}

                                    {drop.source.map((source, idx) => (
                                        <div className="dinamic_quest_block">
                                            <div className="form-group clear clearfix">
                                                <div className="label-part">If</div>
                                                <div className="ans-part">
                                                    <select className="form-control" style={{ width: '90%', display: 'inline-block' }} name="handler" onChange={e => this.addNewCondtions(e, drop, "source", idx)}>
                                                        <option value="" disabled selected>
                                                            Select your option
                                                    </option>
                                                        {this.props.drops.map((subdrop, index) => (
                                                            <option
                                                                key={index}
                                                                disabled={
                                                                    subdrop.type === "info"
                                                                        ? "disabled" :
                                                                        subdrop.type === "gps"
                                                                            ? "disabled"
                                                                            : subdrop.properties.marker_enabled === 1 && !subdrop.properties.instruction_enabled && !subdrop.properties.scale_enabled
                                                                                ? "disabled"
                                                                                : subdrop.properties.marker_enabled === 0 && !subdrop.properties.instruction_enabled && !subdrop.properties.scale_enabled
                                                                                    ? "disabled"
                                                                                    : ""
                                                                }
                                                                selected={source.handler === subdrop.handler ? "selected" : ""}
                                                                value={subdrop.handler + "." + subdrop.type}
                                                            >
                                                                {subdrop.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fa fa-trash" onClick={() => this.deleteSource(index, idx)} />
                                                </div>
                                            </div>
                                            {source.source_type === "input" || this.selectedElement(drop, source).properties.instruction_enabled === 1 ? (
                                                <div className="form-group clear clearfix">
                                                    <div className="label-part">State</div>
                                                    <div className="ans-part">
                                                        <select
                                                            className="form-control"
                                                            name="state"
                                                            //disabled={source.handler ? "" : "disabled"}
                                                            onChange={e => this.addNewCondtions(e, drop, "source", idx)}
                                                        >
                                                            <option value="" disabled selected={!source.state || source.state === "" ? "selected" : ""}>
                                                                Select your option
                                                        </option>
                                                            <option selected={source.state === "equal" ? "selected" : ""} value="equal">
                                                                Is Equal to
                                                        </option>
                                                            <option selected={source.state === "notequal" ? "selected" : ""} value="notequal">
                                                                Is Not Equal to
                                                        </option>
                                                            <option selected={source.state === "contain" ? "selected" : ""} value="contain">
                                                                Contains
                                                        </option>
                                                            <option selected={source.state === "notcontains" ? "selected" : ""} value="notcontains">
                                                                Does Not Contains
                                                        </option>
                                                            <option selected={source.state === "starts" ? "selected" : ""} value="starts">
                                                                Starts With
                                                        </option>
                                                            <option selected={source.state === "notstarts" ? "selected" : ""} value="notstarts">
                                                                Does Not Starts with
                                                        </option>
                                                            <option selected={source.state === "ends" ? "selected" : ""} value="ends">
                                                                Ends With
                                                        </option>
                                                            <option selected={source.state === "notends" ? "selected" : ""} value="notends">
                                                                Does Not Ends with
                                                        </option>
                                                            <option selected={source.state === "empty" ? "selected" : ""} value="empty">
                                                                Is Empty
                                                        </option>
                                                            <option selected={source.state === "filled" ? "selected" : ""} value="filled">
                                                                Is Filled
                                                        </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            ) : source.source_type === "dropdown" || source.source_type === "Single_Select" || source.source_type === "Multiple_Select" || this.selectedElement(drop, source).properties.scale_enabled === 1 ? (

                                                <div className="form-group clear clearfix">
                                                    <div className="label-part">State</div>
                                                    <div className="ans-part">

                                                        <select
                                                            className="form-control"
                                                            name="state"
                                                            //disabled={source.handler ? "" : "disabled"}
                                                            onChange={e => this.addNewCondtions(e, drop, "source", idx)}
                                                        >
                                                            <option value="" disabled selected={!source.state || source.state === "" ? "selected" : ""}>
                                                                Select your option
                                                        </option>
                                                            <option selected={source.state === "equal" ? "selected" : ""} value="equal">
                                                                Is Equal to
                                                        </option>
                                                            <option selected={source.state === "notequal" ? "selected" : ""} value="notequal">
                                                                Is Not Equal to
                                                        </option>
                                                            <option selected={source.state === "empty" ? "selected" : ""} value="empty">
                                                                Is Empty
                                                        </option>
                                                            <option selected={source.state === "filled" ? "selected" : ""} value="filled">
                                                                Is Filled
                                                        </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            ) : this.selectedElement(drop, source).properties.scale_enabled === 1 ? (
                                                <div className="form-group clear clearfix">
                                                    <div className="label-part">State</div>
                                                    <div className="ans-part">
                                                        <select
                                                            className="form-control"
                                                            name="state"
                                                            //disabled={source.handler ? "" : "disabled"}
                                                            onChange={e => this.addNewCondtions(e, drop, "source", idx)}
                                                        >
                                                            <option value="" disabled selected={!source.state || source.state === "" ? "selected" : ""}>
                                                                Select your option
                                                        </option>
                                                            <option selected={source.state === "equal" ? "selected" : ""} value="equal">
                                                                Is Equal to
                                                        </option>
                                                            <option selected={source.state === "notequal" ? "selected" : ""} value="notequal">
                                                                Is Not Equal to
                                                        </option>
                                                            <option selected={source.state === "empty" ? "selected" : ""} value="empty">
                                                                Is Empty
                                                        </option>
                                                            <option selected={source.state === "filled" ? "selected" : ""} value="filled">
                                                                Is Filled
                                                        </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            ) : source.source_type === "upload" ? (
                                                <div className="form-group clear clearfix">
                                                    <div className="label-part">State</div>
                                                    <div className="ans-part">
                                                        <select
                                                            className="form-control"
                                                            name="state"
                                                            //disabled={source.handler ? "" : "disabled"}
                                                            onChange={e => this.addNewCondtions(e, drop, "source", idx)}
                                                        >
                                                            <option value="" disabled selected={!source.state || source.state === "" ? "selected" : ""}>
                                                                Select your option
                                                            </option>
                                                            <option selected={source.state === "empty" ? "selected" : ""} value="empty">
                                                                Is Empty
                                                            </option>
                                                            <option selected={source.state === "filled" ? "selected" : ""} value="filled">
                                                                Is Filled
                                                            </option>
                                                        </select>
                                                    </div>

                                                </div>
                                            )

                                                            : source.source_type === "barcode" && this.selectedElement(drop, source).properties.barcode_enabled === 1 ? (
                                                                <div className="form-group clear clearfix">
                                                                    <div className="label-part">State</div>
                                                                    <div className="ans-part">
                                                                        <select
                                                                            className="form-control"
                                                                            name="state"
                                                                            //disabled={source.handler ? "" : "disabled"}
                                                                            onChange={e => this.addNewCondtions(e, drop, "source", idx)}
                                                                        >
                                                                            <option value="" disabled selected={!source.state || source.state === "" ? "selected" : ""}>
                                                                                Select your option
                                                                </option>
                                                                            <option selected={source.state === "equal" ? "selected" : ""} value="equal">
                                                                                Is Equal to
                                                                </option>
                                                                            <option selected={source.state === "notequal" ? "selected" : ""} value="notequal">
                                                                                Is Not Equal to
                                                                </option>
                                                                            <option selected={source.state === "empty" ? "selected" : ""} value="empty">
                                                                                Is Empty
                                                                </option>
                                                                            <option selected={source.state === "filled" ? "selected" : ""} value="filled">
                                                                                Is Filled
                                                                </option>
                                                                        </select>
                                                                    </div>

                                                                </div>
                                                            )

                                                                : source.source_type === "barcode" && !this.selectedElement(drop, source).properties.barcode_enabled ? (
                                                                    <div className="form-group clear clearfix">
                                                                        <div className="label-part">State</div>
                                                                        <div className="ans-part">
                                                                            <select
                                                                                className="form-control"
                                                                                name="state"
                                                                                //disabled={source.handler ? "" : "disabled"}
                                                                                onChange={e => this.addNewCondtions(e, drop, "source", idx)}
                                                                            >
                                                                                <option value="" disabled selected={!source.state || source.state === "" ? "selected" : ""}>
                                                                                    Select your option
                                                                    </option>
                                                                                <option selected={source.state === "equal" ? "selected" : ""} value="equal">
                                                                                    Is Equal to
                                                                    </option>
                                                                                <option selected={source.state === "notequal" ? "selected" : ""} value="notequal">
                                                                                    Is Not Equal to
                                                                    </option>
                                                                                <option selected={source.state === "contain" ? "selected" : ""} value="contain">
                                                                                    Contains
                                                                    </option>
                                                                                <option selected={source.state === "notcontains" ? "selected" : ""} value="notcontains">
                                                                                    Does Not Contains
                                                                    </option>
                                                                                <option selected={source.state === "starts" ? "selected" : ""} value="starts">
                                                                                    Starts With
                                                                    </option>
                                                                                <option selected={source.state === "notstarts" ? "selected" : ""} value="notstarts">
                                                                                    Does Not Starts with
                                                                    </option>
                                                                                <option selected={source.state === "ends" ? "selected" : ""} value="ends">
                                                                                    Ends With
                                                                    </option>
                                                                                <option selected={source.state === "notends" ? "selected" : ""} value="notends">
                                                                                    Does Not Ends with
                                                                    </option>
                                                                                <option selected={source.state === "empty" ? "selected" : ""} value="empty">
                                                                                    Is Empty
                                                                    </option>
                                                                                <option selected={source.state === "filled" ? "selected" : ""} value="filled">
                                                                                    Is Filled
                                                                    </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                )

                                                                    : source.source_type === "scale" ? (

                                                                        <div>

                                                                            {this.tableList(drop, source) === "table" ?
                                                                                <div className="form-group clear clearfix">
                                                                                    <div className="label-part">State</div>
                                                                                    <div className="ans-part">
                                                                                        <select
                                                                                            className="form-control"
                                                                                            name="state"
                                                                                            //disabled={source.handler ? "" : "disabled"}
                                                                                            onChange={e => this.addNewCondtions(e, drop, "source", idx, "table")}
                                                                                        >
                                                                                            <option value="" disabled selected={!source.state || source.state === "" ? "selected" : ""}>
                                                                                                Select your option
                                                                </option>
                                                                                            <option selected={source.state === "equal" ? "selected" : ""} value="equal">
                                                                                                Is Equal to
                                                                </option>
                                                                                            <option selected={source.state === "notequal" ? "selected" : ""} value="notequal">
                                                                                                Is Not Equal to
                                                                </option>
                                                                                            <option selected={source.state === "empty" ? "selected" : ""} value="empty">
                                                                                                Is Empty
                                                                </option>
                                                                                            <option selected={source.state === "filled" ? "selected" : ""} value="filled">
                                                                                                Is Filled
                                                                </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="form-group clear clearfix">
                                                                                    <div className="label-part">State</div>
                                                                                    <div className="ans-part">
                                                                                        <select
                                                                                            className="form-control"
                                                                                            name="state"
                                                                                            //disabled={source.handler ? "" : "disabled"}
                                                                                            onChange={e => this.addNewCondtions(e, drop, "source", idx)}
                                                                                        >
                                                                                            <option value="" disabled selected={!source.state || source.state === "" ? "selected" : ""}>
                                                                                                Select your option
                                                                </option>
                                                                                            <option selected={source.state === "equal" ? "selected" : ""} value="equal">
                                                                                                Is Equal to
                                                                </option>
                                                                                            <option selected={source.state === "notequal" ? "selected" : ""} value="notequal">
                                                                                                Is Not Equal to
                                                                </option>
                                                                                            <option selected={source.state === "empty" ? "selected" : ""} value="empty">
                                                                                                Is Empty
                                                                </option>
                                                                                            <option selected={source.state === "filled" ? "selected" : ""} value="filled">
                                                                                                Is Filled
                                                                </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )

                                                                        : (
                                                                            ""
                                                                        )}
                                            {this.tableList(drop, source) === "table" && source.state !== "empty" && source.state !== "filled" ? (
                                                <div>
                                                    <div className="form-group clear clearfix">
                                                        <div className="label-part">Value</div>
                                                        <div className="ans-part">
                                                            <select className="form-control" name="match_value" onChange={e => this.addNewCondtions(e, drop, "source", idx)}>
                                                                <option value="" disabled selected>
                                                                    Select your option
                                                            </option>
                                                                {this.valueList(drop, source)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group clear clearfix">
                                                        <div className="label-part">Options</div>
                                                        <div className="ans-part">
                                                            <select className="form-control" name="match_option" onChange={e => this.addNewCondtions(e, drop, "source", idx)}>
                                                                <option value="" disabled selected>
                                                                    Select your option
                                                            </option>
                                                                {this.optionsList(drop, source)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                    ""
                                                )}

                                            {source.state === "empty" || !source.state || this.tableList(drop, source) === "table" || source.state === "filled" ? (
                                                ""
                                            ) : (
                                                    <div>
                                                        <div className="form-group clear clearfix">
                                                            <div className="label-part">Target</div>
                                                            <div className="ans-part">
                                                                <select className="form-control" name="target" onChange={e => this.addNewCondtions(e, drop, "source", idx)}>
                                                                    <option value="" disabled selected>
                                                                        Select your option
                                                            </option>
                                                                    {source.state === "equal" && this.selectedElement(drop, source).properties.scale_enabled === 1 && !this.selectedElement(drop, source).properties.instruction_enabled ?
                                                                        "" : source.state === "notequal" && this.selectedElement(drop, source).properties.scale_enabled === 1 && !this.selectedElement(drop, source).properties.instruction_enabled ?
                                                                            "" : <option selected={source.target === "value" ? "selected" : ""} value="value">
                                                                                Value
                                                                </option>}
                                                                    <option selected={source.target === "field" ? "selected" : ""} value="field">
                                                                        Another Fields
                                                            </option>
                                                                    {source.state === "equal" && this.selectedElement(drop, source).properties.scale_enabled === 1 ?
                                                                        <option selected={source.target === "scale_value" ? "selected" : ""} value="scale_value">
                                                                            Scale Value
                                                                </option>
                                                                        :
                                                                        source.state === "notequal" && this.selectedElement(drop, source).properties.scale_enabled === 1 ?
                                                                            <option selected={source.target === "scale_value" ? "selected" : ""} value="scale_value">
                                                                                Scale Value
                                                                    </option>
                                                                            : ""}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {source.target === "value" && source.source_type !== "scale" && source.source_type !== "dropdown" &&source.source_type !== "Single_Select" && source.source_type !== "Multiple_Select" && source.source_type !== "barcode" && this.selectedElement(drop, source).properties.scale_enabled !== 1 ? (
                                                            <div className="form-group clear clearfix">
                                                                <div className="label-part">Value</div>
                                                                <div className="ans-part">
                                                                    <input
                                                                        className="form-control"
                                                                        name="match_value"
                                                                        value={source.match_value}
                                                                        placeholder="Please type a value here"
                                                                        onChange={e => this.addNewCondtions(e, drop, "source", idx)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) :

                                                            (source.target === "value" && source.source_type === "barcode" && this.selectedElement(drop, source).properties.barcode_enabled === 1) ? (
                                                                <div className="form-group clear clearfix">
                                                                    <div className="label-part">Value</div>
                                                                    <div className="ans-part">
                                                                        <select className="form-control" name="match_value" onChange={e => this.addNewCondtions(e, drop, "source", idx)}>
                                                                            <option value="" disabled selected>
                                                                                Select your option
                                                                        </option>
                                                                            {this.barcodeList(drop, source)}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            ) :

                                                                (source.target === "value" && source.source_type === "barcode" && !this.selectedElement(drop, source).properties.barcode_enabled) ? (
                                                                    <div className="form-group clear clearfix">
                                                                        <div className="label-part">Value</div>
                                                                        <div className="ans-part">
                                                                            <input
                                                                                className="form-control"
                                                                                name="match_value"
                                                                                value={source.match_value}
                                                                                placeholder="Please type a value here"
                                                                                onChange={e => this.addNewCondtions(e, drop, "source", idx)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ) :

                                                                    (source.target === "value" && this.selectedElement(drop, source).properties.scale_enabled === 1) && !this.selectedElement(drop, source).properties.instruction_enabled ? (
                                                                        <div className="form-group clear clearfix">
                                                                            <div className="label-part">Value</div>
                                                                            <div className="ans-part">
                                                                                <select className="form-control" name="match_value" onChange={e => this.addNewCondtions(e, drop, "source", idx)}>
                                                                                    <option value="" disabled selected>
                                                                                        Select your option
                                                                            </option>
                                                                                    {this.selectedElement(drop, source).properties.scale_images.map((subval, subind) => (
                                                                                        <option selected={source.match_value === subind + 1 ? "selected" : ""} value={subind + 1}>
                                                                                            {subind + 1}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    ) :

                                                                        source.target === "value" && this.selectedElement(drop, source).properties.instruction_enabled === 1 ? (
                                                                            <div className="form-group clear clearfix">
                                                                                <div className="label-part">Value</div>
                                                                                <div className="ans-part">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        name="match_value"
                                                                                        value={source.match_value}
                                                                                        placeholder="Please type a value here"
                                                                                        onChange={e => this.addNewCondtions(e, drop, "source", idx)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ) :

                                                                            source.target === "value" && (source.source_type === "dropdown" || source.source_type === "Single_Select" || source.source_type === "Multiple_Select") ? (
                                                                                <div className="form-group clear clearfix">
                                                                                    <div className="label-part">Value</div>
                                                                                    <div className="ans-part">
                                                                                        <select className="form-control" name="match_value" onChange={e => this.addNewCondtions(e, drop, "source", idx)}>
                                                                                            <option value="" disabled selected>
                                                                                                Select your option
                                                                                    </option>
                                                                                            {this.choiceList(drop, source)}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            ) :

                                                                                source.target === "scale_value" ? (
                                                                                    <div className="form-group clear clearfix">
                                                                                        <div className="label-part">Value</div>

                                                                                        <div className="ans-part">
                                                                                            <select className="form-control" name="match_value" onChange={e => this.addNewCondtions(e, drop, "source", idx)}>
                                                                                                <option value="" disabled selected>
                                                                                                    Select your option
                                                                                    </option>
                                                                                                {this.selectedElement(drop).properties.scale_images.map((subval, subind) => (
                                                                                                    <option selected={source.match_value === subind + 1 ? "selected" : ""} value={subind + 1}>
                                                                                                        {subind + 1}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                ) :





                                                                                    (source.target === "value" && source.source_type === "scale") ? (
                                                                                        <div className="form-group clear clearfix">
                                                                                            <div className="label-part">Value</div>
                                                                                            <div className="ans-part">
                                                                                                <select className="form-control" name="match_value" onChange={e => this.addNewCondtions(e, drop, "source", idx)}>
                                                                                                    <option value="" disabled selected>
                                                                                                        Select your option
                                                                            </option>
                                                                                                    {this.scaleList(drop, source)}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) :

                                                                                        (
                                                                                            ""
                                                                                        )}
                                                        {source.target === "field" ? (
                                                            <div className="form-group clear clearfix">
                                                                <div className="label-part">Field</div>
                                                                <div className="ans-part">
                                                                    <select className="form-control" name="matchid" onChange={e => this.addNewCondtions(e, drop, "source", idx)}>
                                                                        <option value="" disabled selected>
                                                                            Select your option
                                                                </option>
                                                                        {this.props.drops.map((subdrop, index) => (
                                                                            <option key={index} selected={source.matchid === subdrop.handler ? "selected" : ""} value={subdrop.handler}>
                                                                                {subdrop.label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                                ""
                                                            )}
                                                    </div>
                                                )}

                                        </div>

                                    ))}
                                    <div className="dinamic_quest_block">
                                        <div className="form-group clear clearfix">
                                            <div className="label-part">Rule</div>
                                            <div className="ans-part">
                                                <select
                                                    className="form-control"
                                                    name="rule"
                                                    onChange={e =>
                                                        this.addNewCondtions(e, drop, "rule")
                                                    }
                                                >
                                                    <option value="" disabled selected>
                                                        Select your option
											  </option>
                                                    <option
                                                        selected={
                                                            drop.rule === "and" ? "selected" : ""
                                                        }
                                                        value="and"
                                                    >
                                                        AND
											  </option>
                                                    <option
                                                        selected={
                                                            drop.rule === "any" ? "selected" : ""
                                                        }
                                                        value="any"
                                                    >
                                                        ANY
											  </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="dinamic_quest_block">
                                        <div className="form-group clear clearfix">
                                            <div className="label-part">Do</div>
                                            <div className="ans-part">
                                                <select className="form-control" name="do" onChange={e => this.addNewCondtions(e, drop, "target")}>
                                                    <option value="" disabled selected>
                                                        Select your option
                                                    </option>
                                                    <option selected={drop.target.do === "hide" ? "selected" : ""} value="hide">
                                                        Hide
                                                    </option>
                                                    <option selected={drop.target.do === "show" ? "selected" : ""} value="show">
                                                        Show
                                                    </option>
                                                    <option selected={drop.target.do === "hide_multiple" ? "selected" : ""} value="hide_multiple">
                                                        Hide Multiple
                                                    </option>
                                                    <option selected={drop.target.do === "show_multiple" ? "selected" : ""} value="show_multiple">
                                                        Show Multiple
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        {this.state.conditions[index].target.do ? (
                                            <div className="form-group clear clearfix">
                                                <div className="label-part">Field</div>
                                                <div className="ans-part">
                                                    {this.state.conditions[index].target.do === "hide" || this.state.conditions[index].target.do === "show" ? (
                                                        <select className="form-control" name="handler" onChange={e => this.addNewCondtions(e, drop, "target")}>
                                                            <option value="" disabled selected>
                                                                Select your option
                                                            </option>
                                                            {this.doList(drop).map((subdrop, index) => (
                                                                <option key={index} selected={drop.target.handler === subdrop.handler ? "selected" : ""} value={subdrop.handler}>
                                                                    {subdrop.label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    ) : this.state.conditions[index].target.do === "hide_multiple" || this.state.conditions[index].target.do === "show_multiple" ? (
                                                        <Select
                                                            isMulti
                                                            name="multifield"
                                                            options={this.multiList(drop)}
                                                            closeMenuOnSelect={false}
                                                            value={drop.target.multifield}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            onChange={e => this.addNewCondtionsMulti(e, drop, "multifield", "target")}
                                                        />
                                                    ) : (
                                                                ""
                                                            )}
                                                </div>
                                            </div>
                                        ) : (
                                                ""
                                            )}
                                    </div>
                                    <div
                                        className="addbtn"
                                        onClick={() => this.addMultiSource(index)}
                                    >
                                        <i className="fa fa-plus" />
                                    </div>
                                    <div className="deletbtn" onClick={() => this.deleteCondtion(index)}>
                                        <i className="fa fa-trash" />
                                    </div>
                                </div>
                            </div>
                        ))}
                        {(this.state.validate === 1) ? <p className="conditionerror"> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>  Kindly Fill all the Fields to add more Conditions</p> : ""}
                        <div className="plus plus-btns" onClick={this.addCondtion}>
                            <i className="fa fa-plus-circle" />{" "}
                        </div>
                        {/*  <div className="" onClick={this.saveCondition}>Save</div> */}
                    </div>
                </div>
            </div>
        );
    }
}
export default withStyles(styles)(Settings);
