/**
 * @Login
 *
 * @author Navaneeth M <navneetnivu07@gmail.com>
 */

import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";

// Resources
import logo from "assets/img/Outcome.png";
import loginbg from "assets/img/bg.png";
import "./Login.css";

// Material UI
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Person from "@material-ui/icons/Person";
import InputAdornment from "@material-ui/core/InputAdornment";

// Custom Components
import Loading from "components/Loading/Loading.jsx";

// bootstrat 1.0
import { Alert, Row } from "react-bootstrap";

// MUI Icons
import LockOpen from "@material-ui/icons/LockOpen";

const styles = theme => ({
  icon: {
    color: "black"
  },
  cssUnderline: {
    color: "black",
    borderBottom: "black",
    borderBottomColor: "black",

    "&:after": {
      borderBottomColor: "black",
      borderBottom: "black"
    },
    "&:before": {
      borderBottomColor: "black",
      borderBottom: "black"
    }
  }
});

const theme = createMuiTheme({
  palette: {
    primary: { main: "#fff" }
  }
});


class Login extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      username: "",
      password: "",
      isAuthenticated: false,
      error: false,
      errorMsg: "",
      toggle: true,

      // loading
      loading: false
    };
  }

  openLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  toggleMode = () => {
    this.setState({ toggle: !this.state.toggle, errorMsg: "" });
  };

  handleReset = e => {
    // const { username } = this.state;
    // this.openLoading();
    // api2
    //   .post("auth/admin/forgotPassword", { email: username })
    //   .then(resp => {
    //     this.stopLoading();
    //     return this.setState({
    //       error: true,
    //       errorMsg:
    //         "Password reset link has been sent to your registered email address."
    //     });
    //   })
    //   .catch(error => {
    //     this.stopLoading();
    //     console.error(error);
    //     return this.setState({
    //       error: true,
    //       errorMsg: "Invalid Credentials. Try again!"
    //     });
    //   });
  };

  handleSubmit = event => {
    this.setState({
      isAuthenticated: true
    });
  };

  handleInputChange = event => {
   const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };


  forgotPassword = () => {
    console.log("object");
  };
  render() {
    const { error } = this.state;

    const { isAuthenticated } = this.state;

    const { classes } = this.props;

    if (isAuthenticated) {
      return <Redirect to="/home/surveys" />;
    }

    return (
        <div className="login-page">
          {/* <video autoPlay muted loop id="myVideo">
            <source src={Constants.BANNER_URI} type="video/mp4" />
          </video> */}

          {/* <div className="videoOver" /> */}
          <div className="left-area">
            <img src={loginbg} className="Login-img" />
            <div className="purple-overlay"></div>
          </div>
          <div className="right-area">

          
          <div className="midl">
            {<Row className="d-flex justify-content-center">
              <img src={logo} className="Login-logo" alt="logo" />
            </Row>
            /*<Row className="d-flex justify-content-center">
              {error && (
                <Alert style={{ color: "#fff" }}>{this.state.errorMsg}</Alert>
              )}
            </Row> */}
            <MuiThemeProvider theme={theme}>
              <Row className="d-flex justify-content-center custom-w-300">
                <TextField
                  id="input-username"
                  name="username"
                  type="text"
                  value={this.state.username}
                  onChange={this.handleInputChange}
                  InputProps={{
                    className: classes.icon,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person className={classes.icon} />
                      </InputAdornment>
                    )
                  }}
                />
              </Row>

              {this.state.toggle ? (
                <Fragment>
                  <br />

                  <Row className="d-flex justify-content-center custom-w-300">
                    <TextField
                      id="input-password"
                      name="password"
                      type="password"
                      value={this.state.password}
                      onChange={this.handleInputChange}
                      className={classes.cssUnderline}
                      InputProps={{
                        className: classes.icon,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOpen className={classes.icon} />
                        </InputAdornment>
                        )
                      }}
                    />
                  </Row>
                </Fragment>
              ) : (
                ""
              )}
            </MuiThemeProvider>
            <br />

            <Row className="d-flex justify-content-left">
              {this.state.toggle ? (
                <Button
                  className="buttonColor"
                  type="submit"
                  onClick={this.handleSubmit}
                  // style={{ backgroundColor: "rgb(199, 21, 133)" }}
                >
                  Login
                </Button>
              ) : (
                <Button
                  className="buttonColor"
                  type="submit"
                  // style={{ backgroundColor: "#c71585" }}
                >
                  Reset
                </Button>
              )}
            </Row>
            <Row className="d-flex justify-content-left">
              <small
                onClick={this.toggleMode}
                className="text-link margin-adjust-left-32"
                style={{ color: "black" }}
              >
                {this.state.toggle ? "Forgot password?" : "Login"}
              </small>
            </Row>
          </div>
          <Loading open={this.state.loading} onClose={this.handleClose} />
          </div>
        </div>
    );
  }
}

export default withStyles(styles)(Login);
