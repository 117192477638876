import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Surveylist from "./views/SurveyList/SurveyList";
import CreateSurvey from "./views/CreateSurvey/CreateSurvey";

// Custom Components
import LeftNav from "components/LeftNav/LeftNav";

class Home extends React.Component {
  constructor() {
    super();

    this.state = {
      fullWidth: true,
      isMobile: false,
      apiKey: localStorage.getItem("api_key"),
      countries: []
    };
    this.childLeftNav = React.createRef();
  }
  componentDidMount() {
    this.setState({ apiKey: localStorage.getItem("api_key") });
    // this.getCountryList();
    if (window.innerWidth < 769) {
      this.setState({
        isMobile: true
      });
    } else {
      this.setState({ isMobile: false });
    }
  }

  // getCountryList() {
  //   api2
  //     .get("v1/customer/countries")
  //     .then(resp => {
  //       let countries = resp.data.list.map(c => ({
  //         label: c.name,
  //         value: c.id
  //       }));
  //       this.setState({
  //         countries: countries
  //       });
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });
  // }

  renameProp = (oldProp, newProp, { [oldProp]: old, ...others }) => {
    return {
      [newProp]: old,
      ...others
    };
  };

  handleFullscreen = () => {
    //this.setState({ fullWidth: !this.state.fullWidth });
  };

  handleCollapseScreen = fullWidth => {
    //this.setState({ fullWidth });
  };

  updateDepartmentList = () => {
    //this.childLeftNav.current.updateDepartmentList();
  };

  render() {
    // if (
    //   this.state.apiKey === null ||
    //   this.state.apiKey === undefined ||
    //   this.state.apiKey.length === 0
    // ) {
    //   return <Redirect to="/" />;
    // }
    return (
      <React.Fragment>
        <div className="App-body">
          <LeftNav
            fullWidth={this.state.fullWidth}
            handleFullscreen={this.handleFullscreen}
            isMobile={this.state.isMobile}
            ref={this.childLeftNav}
          />
          <Switch>
            <Route
              path="/home/surveys"
              exact={true}
              render={props => (
                <Surveylist
                  {...props}
                  handleCollapseScreen={this.handleCollapseScreen}
                  fullWidth={this.state.fullWidth}
                  isMobile={this.state.isMobile}
                />
              )}
            />
            <Route
              path="/home/create-survey"
              exact={true}
              render={props => (
                <CreateSurvey
                  {...props}
                  handleCollapseScreen={this.handleCollapseScreen}
                  fullWidth={this.state.fullWidth}
                  isMobile={this.state.isMobile}
                />
              )}
            />

            <Route
              path="/home/view-survey/:id"
              exact={true}
              render={props => (
                <CreateSurvey
                  {...props}
                  handleCollapseScreen={this.handleCollapseScreen}
                  fullWidth={this.state.fullWidth}
                  isMobile={this.state.isMobile}
                />
              )}
            />
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
