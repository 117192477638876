export const clnContent = [
    {
        id: 1,
        icon: "chart-line",
        label: "Responses and Reports",
        to: "/home/view-client-screen"
    },
]

export const clnContentCollapsed = [
    {
        id: 1,
        icon: "chart-line",
        to: "/home/view-client-screen"
    }
]

export const clnMobileContent = [
    {
      id: 1,
      icon: "bars",
      label: "Eolas"
    },
    {
        id: 2,
        parentId: 1,
        icon: "chart-line",
        label: "Responses and Reports",
        to: "/home/view-client-screen"
    }
]

export default {
    clnContent,
    clnContentCollapsed,
    clnMobileContent
  };