import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";


const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#074e9e"
        },
        secondary: {
            main: "#ffffff"
        }
    }
});

const styles = {
    loadingDiv: {
        width: "100%",
        height: 'calc(100% - 60px)',
        justifyContent: "center"
    },
    paper: {
        position: "absolute",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        // padding: theme.spacing.unit * 4,
        outline: "none"
    },
    Input: {
        borderBottom: 'none'
    }
}

class Bulkinset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            textinput: '',
            value: '',
            sectionvalue: '',
            valuesection: '',
            drops: [],


        }
    }
    componentWillMount() {
        this.setbulkinsert();
        this.setState({
            drops: this.props.oldprop,
            visible: this.props.openbulkinset
        })
    }

    componentWillReceiveProps(nextprops) {
        this.setState({
            drops: nextprops.oldprop,
            visible: nextprops.openbulkinset
        })
    }

    openModal() {
        this.setState({
            visible: true
        });
    }

    getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
            width: `40%`,
            height: `60%`,
            borderRadius: `5px`
        };
    }

    closeModal() {
        this.setState({
            visible: false
        });
        this.props.closebulkinset();
    }
    updatevalue = (e) => {        
        this.setState({ textinput: e.target.value })
    }

    setbulkinsert =()=>{
        let fieldprops = this.props.oldprop;
        let text = this.state.textinput;
        if(fieldprops.properties && fieldprops.properties.options && fieldprops.properties.options.length > 0){
            fieldprops.properties.options.map(o=>{
                if(o.weight && o.weight !== ''){
                    text = text ==='' ? o.label + '#' + o.weight: text + '\n' + o.label + '#' + o.weight;
                } else{
                text = text ==='' ? o.label : text + '\n' + o.label;
                }
            })
        }
        this.setState({
            textinput:text
        })

    }

    Updateoptions=()=>{
        let text = this.state.textinput;
        var splitted = text.split("\n");
        let fieldprops = this.state.drops
        let e = 'options';
        let options =[];
        let manual = fieldprops.properties[`${e}`] ? fieldprops.properties[`${e}`].length : 0;
        if (!fieldprops.properties[`${e}`]) {
            fieldprops.properties[`${e}`] = [];
            splitted.map(m=>{
                let weight = m.split('#')[1];
                let labels = m.split('#')[0]
                options.push({ id: manual, label: labels, label_image: "",weight:weight });
                manual =manual+1;               
            })
            fieldprops.properties[`${e}`] = options;
        } else {
            splitted.map(m=>{
                let weight = m.split('#')[1];
                let labels = m.split('#')[0]
                options.push({ id: manual, label: labels, label_image: "",weight:weight });
            manual =manual+1;
            })  
            fieldprops.properties[`${e}`] = options;
        } 
        this.setState({ textinput: '',visible: false})
        this.props.closebulkinset();
    }

    render() {
        const { classes } = this.props;
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.state.visible}
                onClose={() => { this.closeModal() }}
                style={{ height: '100%', width: '100%' }}
            >
                <div style={this.getModalStyle()} className={classes.paper}>
                    <div style={{ height: '10%', width: '100%', backgroundColor: 'grey', padding: '10px' }} >
                        <label style={{ float: 'left', fontSize: 18 }}>Bulk Insert</label>
                    </div>
                    <div style={{ width: '100%', height: '75%', padding: 20 }}>
                        <div>
                            <label>You can Insert a List Of Options</label>
                        </div>
                        <div style={{ border: '1px solid grey', height: '100%' }}
                        onClick={()=>{this.textstring.focus()}}
                        >
                            <TextField
                                multiline={true}
                                style={{ width: '100%', padding: 10 }}
                                value={this.state.textinput}
                                InputProps={{
                                    disableUnderline: true
                                }}
                                inputRef={(text)=>this.textstring = text}
                                onChange={(e) => { this.setState({ textinput: e.target.value }) }}
                            />
                        </div>
                    </div>
                    <div style={{height: '15%', marginRight: 20,float:'right',marginTop:'10px' }}>
                        <Button
                            variant="contained"
                            style={{
                                color: "#fff",
                                backgroundColor: "#074e9e",
                                margin: "10px 0 0px 10px",
                                padding: "5px 16px",
                                fontSize: "12px"
                            }}
                        onClick={() => {this.Updateoptions()}}
                        >
                            ok
                </Button>
                        <Button
                            variant="contained"
                            style={{
                                color: "#fff",
                                backgroundColor: "#074e9e",
                                margin: "10px 0 0px 10px",
                                padding: "5px 16px",
                                fontSize: "12px"
                            }}

                        onClick={() => { this.closeModal() }}
                        >
                            Cancel
                </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withStyles(styles)(Bulkinset)