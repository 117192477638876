import React from "react";
import { DragSource } from "react-dnd";
import { PropTypes } from "prop-types";
import { ITEM } from "./itemTypes";

const Source = ({ color, icon, text, connectDragSource, isDragging, type }) =>
  connectDragSource(
    <div
      className="board__sources__source"
      style={{
        opacity: isDragging ? 0.25 : 1
      }}
    >
      <i className={icon} />
      <p> {text} </p>
    </div>
  );

Source.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired
};

const source = {
  beginDrag(props) {

    const { color } = props;
    const { text } = props;
    const { type } = props;
    return {
      color,
      text,
      type
    };
  },
  endDrag(props, monitor, index) {

    if (!monitor.didDrop()) {
      return;
    }
    const { onDrop } = props;
    const { text } = props;
    const { type } = props;
    const question = "";
    const { dropChange } = props;
    const { question_id } = props;
    const handler = new Date().valueOf();


    const inputlabel = "Text Input " + props.labelval;
    const singleselectlabel = "Single Select Questions " + props.labelval;
    const multipleselectlabel = "Multiple Select Questions " + props.labelval;
    const datelabel = "Date&Time Input " + props.labelval;
    const scalequestionlabel = "Scale/Matrix Question" + props.labelval;
    const dropdownlabel = "Dropdown" + props.labelval;
	const matrixlabel = "Matrix" + props.labelval;
	const textarealabel = "Textarea" + props.labelval;
    const label = type === 'input' ? (inputlabel)
      : type === 'Single_Select' ? (singleselectlabel)
        : type === 'Multiple_Select' ? (multipleselectlabel)
          : type === 'Scale_Question' ? (scalequestionlabel)
            : type === 'Date_Input' ? (datelabel)
              : type === "dropdown" ? (dropdownlabel)
			   : type === "Matrix_Question" ? (matrixlabel)
			   : type === "textarea" ? (textarealabel)
                : false
    const inputprops = {
      question: "Type a question",
      length: "20"
    }

    const singleselectprops = {
      question: "Type ad question",
      choice_type: "Single_Select"
    }

    const multipleselectprops = {
      question: "Type a question",
      choice_type: "Multiple_Select"
    }

    const dateprops = {
      question: "Type a question",
      choice_type: "Date_Input"
    }

    const scalequestionprops = {
      question: "Type a question",
      choice_type: "Scale_Question"
    }

    const dropdownprops = {
      question: "Select a list",
      dropdown_type: "select a option"
    }
	
	const matrixquestionprops = {
      question: "Type a question",
      choice_type: "Matrix_Question",
	  columns: [{ id: 1, value: "BAD", weight:1 },{ id: 2, value: "OK", weight:2 },{ id: 3, value: "Good", weight:3 }]
    }
	
	const textareaprops = {
      question: "Type a question",
      length: "20"
    }


    const conditions = []
    const properties = type === 'input' ? (inputprops)
      : type === 'Single_Select' ? (singleselectprops)
        : type === 'Multiple_Select' ? (multipleselectprops)
          : type === 'Date_Input' ? (dateprops)
            : type === 'Scale_Question' ? (scalequestionprops)
              : type === "dropdown" ? (dropdownprops)
			  : type === "Matrix_Question" ? (matrixquestionprops)
			   : type === "textarea" ? (textareaprops)
                : false

    const { color } = monitor.getItem();
    const { shape } = monitor.getDropResult();
    onDrop(color, shape, text, type, dropChange, handler, question, properties, question_id, label, conditions);
  }
};

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
});

export default DragSource(ITEM, source, collect)(Source);
