/**
 * @preview returns interfaces of survey page.
 *
 * @author Ashok S <ashok@travelinsert.com>
 */

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Radio from "@material-ui/core/Radio";

import allQuestion from "assets/img/all.png";
import singleQuestion from "assets/img/single.png";
import messageQuestion from "assets/img/msg.png";
import Select from "react-select";



import "./SurveyInfo.css";

import { FormControl, FormGroup, Col, Row } from "react-bootstrap";

const styles = theme => ({
  root: {
    width: "100%"
  },
  backButton: {
    marginRight: theme.spacing.unit
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  card: {
    minWidth: "90%"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    marginBottom: 16,
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  stepIcon: {
    color: "#d15c17"
  },
  checked: {}
});

const options = [
  { value: "App Only", label: "App Only" },
  { value: "App & Browser", label: "App & Browser" },
  { value: "Browser Only", label: "Browser Only" }
];

/**
 * components returns the survey information
 */
class SurveyInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      tags: "",
      points: 0,
      minutes: 0,
      selectedValue: "all",
      projectSource: [],
      missions: [],
      projects: [],
      platformType: {},
      isAssigned: ""
    };
  }

  componentDidMount() {
    this.getProjectList();
  }

  getProjectList() {}

  componentWillMount() {
    let option = {};

    for (var i = 0; i < options.length; i++) {
      if (options[i].value == this.props.platformType) {
        option = options[i];
      }
    }

    this.setState({
      name: this.props.surveyname,
      tags: this.props.prevtags,
      points: this.props.points,
      selectedValue: this.props.qtype,
      minutes: this.props.minutes,
      platformType: option,
      isAssigned: this.props.isAssigned
    });
  }

  componentWillReceiveProps(nextProps) {
    let option = {};

    for (var i = 0; i < options.length; i++) {
      if (options[i].value == nextProps.platformType) {
        option = options[i];
      }
    }

    this.setState({
      name: nextProps.surveyname,
      tags: nextProps.prevtags,
      points: nextProps.points,
      selectedValue: nextProps.qtype,
      minutes: nextProps.minutes,
      platformType: option
    });
  }

  handlePlatformType = event => {
    this.setState({
      platformType: event
    });

    this.props.handlePlatformType(event);
  };

  /**
   * handles the input elements
   *
   * @public
   */
  handleInputChange = event => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    if (name === "points" || name === "minutes") value = parseInt(value);

    this.setState({
      [name]: value
    });

    this.props.handleInputChange(event);
  };

  handleInputChange2(name, event) {
    const value = event.value;
    this.setState({
      [name]: value
    });
    this.props.handleprojects(name, value);
  }

  handleProjectChange(name, event) {
    let projectid = event.value;
    const value = event.value;
    this.setState({
      [name]: value
    });
    this.props.handleprojects(name, value);
    this.getMissionList(projectid);
  }

  getMissionList(projectid) {}

  /**
   * handles on changi the state values
   *
   * @public
   */
  handleChange = event => {
    this.setState(
      { selectedValue: event.target.value },
      this.props.handleqtypeChange(event)
    );
  };

  render() {
    return (
      <Fragment>
        <FormGroup controlId="username" className="form-hei-alt mt-5">
          <Col sm={6} className="survey-input">
            <span>Survey Name</span>
            <FormControl
              autoFocus
              className="input-blue"
              name="name"
              type="text"
              placeholder="Survey Name"
              value={this.state.name}
              onChange={this.handleInputChange}
            />
          </Col>
          <br />
          <Col md={6} className="survey-input2">
            <span>Tags</span>
            <FormControl
              className="input-blue"
              name="tags"
              type="text"
              placeholder="#Onboarding, #Screener, #Survey"
              value={this.state.tags}
              onChange={this.handleInputChange}
            />
          </Col>
          <br />
          <Row className="padder">
            <Col md={3} className="survey-input2">
              <span>Survey Points</span>
              <FormControl
                className="input-blue"
                name="points"
                type="number"
                placeholder="Survey Points"
                value={this.state.points}
                onChange={this.handleInputChange}
              />
            </Col>
            {/* <Col md={3}>
              <span style={{ marginLeft: "10px" }}>Survey Minutes</span>
              <FormControl
                className="input-blue"
                name="minutes"
                type="number"
                placeholder="Survey Minutes"
                value={this.state.minutes}
                onChange={this.handleInputChange}
              />
            </Col> */}
          </Row>
          {/* <br />
          <Row className="padder">
            <Col md={3} className="survey-input2">
              <div>
                <span style={{ marginLeft: "10px" }}>Project</span>
                <Select
                  options={this.state.projects}
                  onChange={this.handleProjectChange.bind(this, "project_id")}
                  name="project"
                  className="selectinfo"
                />
              </div>
            </Col>

            <Col md={3}>
              {this.props.isAssigned != "" && this.props.isAssigned == "yes" && (
                <div style={{ textAlign: "center", marginTop: "25px" }}>
                  <span>Platform type: </span>
                  <span style={{ fontWeight: 600 }}>
                    {this.props.platformType}
                  </span>
                </div>
              )}
              {this.props.isAssigned == "" && (
                <div>
                  <span style={{ marginLeft: "10px" }}>Platform type</span>
                  <Select
                    options={options}
                    value={this.state.platformType}
                    onChange={this.handlePlatformType}
                    name="PlatformType"
                    className="selectinfo"
                  />
                </div>
              )}
            </Col>

            {this.state.project_id ? (
              <Col md={3}>
                <span style={{ marginLeft: "10px" }}>Missions</span>
                <Select
                  options={this.state.missions}
                  onChange={this.handleInputChange2.bind(this, "mission_id")}
                  name="missions"
                  className="selectinfo"
                />
              </Col>
            ) : (
              ""
            )}
          </Row> */}
          <br />
          {/* <Col sm={6} className="survey-input">
            <Row className="show-grid">
              <Col xs={4} md={4}>
                <Radio
                  checked={this.state.selectedValue === "all"}
                  onChange={this.handleChange}
                  value="all"
                  name="all"
                  color="primary"
                />
                <br />
                <img src={allQuestion} alt="allQuestion" />
                <Typography variant="body2">
                  All Questions on one page
                </Typography>
              </Col>
              <Col xs={4} md={4}>
                <Radio
                  checked={this.state.selectedValue === "single"}
                  onChange={this.handleChange}
                  value="single"
                  name="single"
                  color="primary"
                />
                <br />
                <img src={singleQuestion} alt="singleQuestion" />
                <Typography variant="body2">
                  Single Question per page
                </Typography>
              </Col>
              <Col xs={4} md={4}>
                <Radio
                  checked={this.state.selectedValue === "message"}
                  onChange={this.handleChange}
                  value="message"
                  name="message"
                  color="primary"
                />
                <br />
                <img src={messageQuestion} alt="messageQuestion" />
                <Typography variant="body2">Messenger</Typography>
              </Col>
            </Row>
          </Col> */}
        </FormGroup>
      </Fragment>
    );
  }
}

SurveyInfo.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(SurveyInfo);
