import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';

// MUI Components
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Icons
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

// Custom Components
import SurveyCard from "components/CustomCards/SurveyCard";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import SortedTable from "components/SortedTable/SortedTable.jsx";
import Card from "components/Card/Card.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

// bootstrat 1.0
import { Button, Form } from "react-bootstrap";

// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

// API
// import api2 from "../../helpers/api2";

// CSS
import "./SurveyList.css";

import { createMuiTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

import CloneModal from "./CloneModal";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#074e9e"
    },
    secondary: {
      main: "#ffffff"
    }
  }
});

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "45%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  gridHeader: {
    width: "100%",
    padding: "17px 15px"
  },
  loadingDiv: {
    width: "100%",
    textAlign: "center"
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  }
};

const condProp1 = {};
condProp1.gps_stats = "show";
condProp1.question = "gps";
const conditionContent1 = [];
const second = {};
// second.conditions = [];
// second.handler = 1571229124782;
// second.label = "GPS 1";
// second.properties = condProp1;
// second.question = "test";
// second.question_id = 2;
// second.rightStatus = false;
// second.type = "gps2";
conditionContent1.push(second);
const conditiontarget1 = {};
conditiontarget1.do = "hide";
conditiontarget1.handler = 1571229122727;
const conditionsource1 = [];
conditionsource1.handler = 1571229120030;
conditionsource1.source_type = "input";
conditionsource1.state = "empty";
const conditionsarray = [];
conditionsarray.condtion_id = 0;
conditionsarray.rule = "any";
conditionsarray.source = conditionsource1;
conditionsarray.target = conditiontarget1;
const secondarray = {};
secondarray.author = "admin";
secondarray.conditions = [conditionsarray];
secondarray.content = conditionContent1;
secondarray.id = 941;
secondarray.modified = "Thu, 17 Oct 2019 03:24:29 GMT";
secondarray.platform_type = "App & Browser";
secondarray.published = "Thu, 17 Oct 2019 08:54:26 GMT";
secondarray.questions = 0;
secondarray.responses = 0;
secondarray.survey_minutes = 0;
secondarray.survey_points = 0;
secondarray.tags = "mediclaim, outcome";
secondarray.title = "Sara";
secondarray.project = "MediClaim";
secondarray.type = "all";

const condProp = {};
condProp.gps_stats = "show";
condProp.question = "gps";
const conditionContent = [];
const first = {};
first.conditions = [];
first.handler = 1571229124782;
first.label = "GPS 1";
first.properties = condProp;
first.question = "gfd";
first.question_id = 2;
first.rightStatus = false;
first.type = "gps";
conditionContent.push(first);
const conditiontarget = {};
conditiontarget.do = "hide";
conditiontarget.handler = 1571229122727;
const conditionsource = [];
conditionsource.handler = 1571229120030;
conditionsource.source_type = "input";
conditionsource.state = "empty";
const conditionsarray1 = [];
conditionsarray1.condtion_id = 0;
conditionsarray1.rule = "any";
conditionsarray1.source = conditionsource;
conditionsarray1.target = conditiontarget;
const firstarray = {};
firstarray.author = "admin";
firstarray.conditions = [conditionsarray1];
firstarray.content = conditionContent;
firstarray.id = 942;
firstarray.modified = "Thu, 17 Oct 2019 03:24:29 GMT";
firstarray.platform_type = "App & Browser";
firstarray.published = "Thu, 17 Oct 2019 08:54:26 GMT";
firstarray.questions = 0;
firstarray.responses = 0;
firstarray.survey_minutes = 0;
firstarray.survey_points = 0;
firstarray.tags = "imeddoc, mediclaim, outcome";
firstarray.title = "Praga";
firstarray.project = "iMedDoc";
firstarray.type = "all";



const condProp2 = {};
condProp2.gps_stats = "show";
condProp2.question = "gps";
const conditionContent2 = [];
const thrid = {};
thrid.conditions = [];
thrid.handler = 1571229124782;
thrid.label = "GPS 1";
thrid.properties = condProp;
thrid.question = "gfd";
thrid.question_id = 2;
thrid.rightStatus = false;
thrid.type = "gps";
conditionContent2.push(thrid);
const conditiontarget2 = {};
conditiontarget2.do = "hide";
conditiontarget2.handler = 1571229122727;
const conditionsource2 = [];
conditionsource2.handler = 1571229120030;
conditionsource2.source_type = "input";
conditionsource2.state = "empty";
const conditionsarray2 = [];
conditionsarray2.condtion_id = 0;
conditionsarray2.rule = "any";
conditionsarray2.source = conditionsource;
conditionsarray2.target = conditiontarget;
const thridarray = {};
thridarray.author = "admin";
thridarray.conditions = [conditionsarray2];
thridarray.content = conditionContent2;
thridarray.id = 942;
thridarray.modified = "Thu, 17 Oct 2019 03:24:29 GMT";
thridarray.platform_type = "App & Browser";
thridarray.published = "Thu, 17 Oct 2019 08:54:26 GMT";
thridarray.questions = 0;
thridarray.responses = 0;
thridarray.survey_minutes = 0;
thridarray.survey_points = 0;
thridarray.tags = "consultant, bhs, portal";
thridarray.title = "Subhu";
thridarray.project = "Consultant Claims";
thridarray.type = "all";
const mainAarray = [];

mainAarray.push(firstarray);
mainAarray.push(secondarray);
mainAarray.push(thridarray);

class SurveyList extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      view: "grid",
      search: "",
      surveys: [],
      filteredSurveys: [],
      response: false,
      message: "",
      dialogOpen: false,
      deleteSurveyName: "",
      deleteSurveyId: "",
      deleteSurveyIndex: "",
      cloneOpen: false,
      cloneSurveyName: "",
      cloneSurveyIndex: "",
      cloneSurveyId: "",
      // snackbar props
      msgColor: "info",
      message: "",
      br: false
    };
  }

  componentDidMount() {
    this.getSurveyList();
  }

  getSurveyList() {
    var self = this;
    console.log(firstarray, mainAarray, "pppppppppppp");

    /* self.setState({
       surveys: mainAarray,
       filteredSurveys: mainAarray,
       response: true
     }); */
    // self.setState({
    //   surveys: [],
    //   filteredSurveys: [],
    //   response: true
    // });


    const formdata = { 
		};
		axios.defaults.headers.common['Authorization'] = "Bearer " + 'very_long_bearer_token';
		 axios.post('/outcomes_get_survey_lists_formjson.php', { formdata })
      .then(resp => {
        console.log(resp);

        self.setState({
          surveys: resp.data,
          filteredSurveys: resp.data,
          response: true
        }); 
        //http://localhost/projects/json_forms/public/api/getSurveyLists
        //http://localhost/projects/b.php
      })
  }

  switchView(view) {
    if (view === "grid") {
      this.setState({
        view: "grid"
      });
    } else if (view === "list") {
      this.setState({
        view: "list"
      });
    }
  }

  filterSurvey = surveyFilter => {
    let filteredSurveys = this.state.surveys;
    filteredSurveys = filteredSurveys.filter(survey => {
      let title = survey.title.toLowerCase();
      let tags = survey.tags.toLowerCase();
      return (
        title.indexOf(surveyFilter.toLowerCase()) !== -1 ||
        tags.indexOf(surveyFilter.toLowerCase()) !== -1
      );
    });
    this.setState({
      filteredSurveys
    });
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

    this.filterSurvey(value);
  };

  deleteItem = (id, index, name) => event => {
    event.preventDefault();
    this.setState({
      dialogOpen: true,
      deleteSurveyId: id,
      deleteSurveyIndex: index,
      deleteSurveyName: name
    });
  };

  cloneItem = (id, index, name) => event => {
    event.preventDefault();
    this.setState({
      cloneOpen: true,
      cloneSurveyId: id,
      cloneSurveyIndex: index,
      cloneSurveyName: "Copy of " + name
    });
  };

  // handleClone = () => {
  //   if (this.state.cloneSurveyId) {
  //     const id = this.state.cloneSurveyId;
  //     let data = {
  //       id: this.state.cloneSurveyId,
  //       name: this.refs.cloneRef.getSurveyname()
  //     };

  //     api2
  //       .post("clone_survey", data)
  //       .then(resp => {
  //         this.setState(
  //           {
  //             cloneOpen: false,
  //             response: false
  //           },
  //           () => {
  //             this.getSurveyList();
  //             this.showNotification("Survey cloned successfully", "success");
  //           }
  //         );
  //       })
  //       .catch(error => {
  //         if (error.response.data && error.response.data.error) {
  //           this.showNotification(error.response.data.error, "danger");
  //         } else {
  //           this.showNotification("Failed to clone the survey", "danger");
  //         }
  //       });
  //   }
  // };

  handleCloseModal = () => {
    this.setState({ cloneOpen: false });
  };

  showNotification = (msg, color) => {
    this.setState({
      message: msg,
      msgColor: color
    });

    let place = "br";
    var x = [];
    x[place] = true;
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        x[place] = false;
        this.setState(x);
      }.bind(this),
      3000
    );
  };

  getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

  // handleDialogClose = deleteSurvey => event => {
  //   if (deleteSurvey) {
  //     api2.delete("survey?id=" + this.state.deleteSurveyId).then(resp => {
  //       this.setState({
  //         surveys: this.state.surveys.filter(
  //           (x, i) => i !== this.state.deleteSurveyIndex
  //         ),
  //         filteredSurveys: this.state.filteredSurveys.filter(
  //           (x, i) => i !== this.state.deleteSurveyIndex
  //         )
  //       });
  //     });
  //   }
  //   this.setState({ dialogOpen: false });
  // };

  render() {
    const { classes } = this.props;
    const { msgColor, br, message } = this.state;

    function Actions(props) {
      return (
        <CardActions>
          <IconButton
            onClick={props.deleteItem(props.id, props.index, props.name)}
            aria-label="Delete"
            className={classes.buttons}
          >
            <DeleteForeverIcon color="action" />
          </IconButton>
        </CardActions>
      );
    }

    const listItems = this.state.filteredSurveys.map((survey, index) => [
      survey.title,
      survey.project,
      survey.tags,
      survey.active,
      survey.createdDate,
      survey.updatedDate,
      <Actions
        id={survey.id}
        index={index}
        name={survey.title}
        deleteItem={this.deleteItem}
      />
    ]);
    console.log(listItems, this.state.filteredSurveys, "chkkkkk");

    const listIds = this.state.filteredSurveys.map(survey => [survey.id]);

    let body_class = this.props.fullWidth
      ? "body-full-expanded"
      : "body-full-collapsed";
    return (
      <div style={{ height: "90%" }} className={body_class}>
        <div className={classes.gridHeader}>
          <Grid
            container
            alignItems="center"
            id="gridHeader"
            style={{ padding: "0%", margin: "0 !important" }}
          >
            <GridItem xs={6} sm={4} md={3}>
              <Typography variant="h5">
                Surveys ({this.state.surveys.length})
              </Typography>
            </GridItem>            

            <GridItem id="filler" xs={1} sm={1} md={1} />

            <GridItem xs={6} sm={4} md={8}>              
              <Link to="/home/create-survey">
                <Button
                  variant="primary"
                  style={{
                    borderRadius: "3px",
                    background: "#3ec143",
                    borderColor: "#3ec143",
                    float: "right"
                  }}
                >
                  <i className="fa fa-plus" aria-hidden="true" />  Create Survey
                </Button>
              </Link>

              <Form.Control
                type="text"
                name="search"
                value={this.state.search}
                onChange={this.handleInputChange}
                style={{ borderRadius: "3px", float: "right", width: "260px", marginRight: "10px" }}
                placeholder="Search"
              />

              <Button
                onClick={() => this.switchView("grid")}
                variant={this.state.view === "grid" ? "active" : ""}
                className={"view-switch-button"}
                style={{ float: "right", marginRight: "10px"}}
              >
                <i className="fa fa-th" aria-hidden="true" />
              </Button>
              <Button
                onClick={() => this.switchView("list")}
                variant={this.state.view === "list" ? "active" : ""}
                className={"view-switch-button"}
                style={{ float: "right" }}
              >
                <i className="fa fa-list" aria-hidden="true" />
              </Button>

            </GridItem>
          </Grid>
        </div>

        {!this.state.response ? (
          <div className={classes.loadingDiv}>
            <CircularProgress className={classes.progress} color="#c71585" />
          </div>
        ) : (
          <Fragment>
            {this.state.surveys.length === 0 && this.state.response ? (
              <div className={classes.loadingDiv}>
                <Typography variant="h5">No Surveys!</Typography>
              </div>
            ) : (
              <Fragment>
                {this.state.view === "grid" ? (
                  <PerfectScrollbar>
                    <div className="list-box">
                      <Grid container spacing={8}>
                        {this.state.filteredSurveys.map((survey, i) => (
                          <Grid
                            item
                            md={4}
                            key={i}
                            style={{
                              width: this.props.isMobile ? "100%" : "auto",
                              marginLeft: this.props.isMobile ? "-1%" : "0"
                            }}
                          >
                            <SurveyCard
                              key={i}
                              survey={survey}
                              index={i}
                              deleteItem={this.deleteItem}
                              cloneItem={this.cloneItem}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </PerfectScrollbar>
                ) : (
                  <PerfectScrollbar>
                    <div className="list-box">
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Card>
                            <SortedTable
                              linkTo="/home/view-survey/"
                              listIds={listIds}
                              tableHead={[
                                "Name",
                                "Project",
                                "Tags",
                                "Active",
                                "Created",
                                "Modified",
                                "Actions"
                              ]}
                              tableData={listItems}
                            />
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </PerfectScrollbar>
                )}
              </Fragment>
            )}
          </Fragment>
        )}

        {/* <AlertDialog
          title={"Delete " + this.state.deleteSurveyName}
          description="Are you sure you want to delete this survey? Once deleted it cannot be retrieved"
          open={this.state.dialogOpen}
          handleDialogClose={this.handleDialogClose}
        /> */}
        <Snackbar
          place="br"
          color={msgColor}
          open={br}
          message={message}
          closeNotification={() => this.setState({ br: false })}
          close
        />

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.cloneOpen}
          // onClose={this.handleCloseModal}
        >
          <PerfectScrollbar>
            <div style={this.getModalStyle()} className={classes.paper}>
              <Typography
                variant="h6"
                id="modal-title "
                style={{ textAlign: "left" }}
              >
                <div>
                  <h5>Enter Survey Name</h5>

                  <CloneModal
                    ref="cloneRef"
                    cloneSurveyName={this.state.cloneSurveyName}
                  />
                </div>

                <div
                  className="model-footer"
                  style={{
                    paddingTop: "5px"
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      color: "#fff",
                      backgroundColor: "#7c54e0",
                      margin: "10px 10px 0 0",
                      padding: "5px 16px",
                      fontSize: ".85rem"
                    }}
                    // onClick={this.handleClone}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      color: "#fff",
                      backgroundColor: "#444",
                      margin: "10px 10px 0 0",
                      padding: "5px 16px",
                      fontSize: ".85rem"
                    }}
                    onClick={this.handleCloseModal}
                  >
                    Cancel
                  </Button>
                </div>
              </Typography>
            </div>
          </PerfectScrollbar>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(SurveyList);
