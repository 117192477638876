import React, { Component } from "react";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import Card from "./Drop";
import Source from "./Source";
import Target from "./Target";
import "./Board.css";
import update from "immutability-helper";
import Popupp from "./Popup";





class Board extends Component {
  constructor(props) {
    super(props);
    this.handleDrop = this.handleDrop.bind(this);
    this.state = {
      drops: [],
      sidebox: true,
      gallery: { images: [] },
      emojis: [],
      scaleico: [],
      infoico: [],
      elementsDefined: false,
      mopen:false,
      
    };

    this.ropen = this.ropen.bind(this);
  }

  componentWillMount() {
    this.setState({
      drops: this.props.olddrops,
      elementsDefined: true
    });
    this.gallerrfun();
    this.getEmojis();
    this.infoGallery();
    this.getScaleimg();
  }
  componentWillReceiveProps(props) {
    /*this.setState({drops: props.olddrops});*/
  }

  componentDidMount() {}

  /**
   * Loads common images from the gallery to state
   *
   */
  gallerrfun = () => {
    // let self = this;
    // api2
    //   .get("v1/gallery/images/common")
    //   .then(resp => {
    //     self.setState({
    //       gallery: resp.data
    //     });
    //   })
    //   .catch(error => {
    //     console.error(error);
    //     self.setState({
    //       response: true
    //     });
    //   });
  };

  /**
   * Loads info images from the gallery to state
   *
   */
  infoGallery = () => {
    // let self = this;
    // api2
    //   .get("v1/gallery/images/info")
    //   .then(resp => {
    //     self.setState({
    //       infoico: resp.data.images
    //     });
    //   })
    //   .catch(error => {
    //     console.error(error);
    //     self.setState({
    //       response: true
    //     });
    //   });
  };

  /**
   * Loads emojis from the gallery to state
   *
   */
  getScaleimg = () => {
    // let self = this;
    // api2
    //   .get("v1/gallery/images/scale")
    //   .then(resp => {
    //     self.setState({
    //       scaleico: resp.data.images
    //     });
    //   })
    //   .catch(error => {
    //     console.error(error);
    //     self.setState({
    //       response: true
    //     });
    //   });
  };

  getEmojis = () => {
    // let self = this;
    // api2
    //   .get("v1/gallery/scale_images")
    //   .then(resp => {
    //     self.setState({
    //       emojis: resp.data.images
    //     });
    //   })
    //   .catch(error => {
    //     console.error(error);
    //     self.setState({
    //       response: true
    //     });
    //   });
  };

  /**
   * handles the setting bar.
   *
   * @public
   * @param {e}
   * @param {status}
   * @param {i}
   *
   */
  ropen(e, status, i) {
    let drops = this.state.drops;
    if (this.state.drops.length <= 0) {
      i = 0;
    }

    for (let temp = 0; temp < drops.length; temp++) {
      if (drops[temp]) {
        drops[temp].rightStatus = false;
      }
    }
    for (let temp = 0; temp < drops.length; temp++) {
      if (drops[temp].question_id == i) {
        i = temp;
        break;
      }
    }

    let self = this;
    setTimeout(function() {
      if (drops[i]) {
        drops[i].rightStatus = status;
      }
      self.setState({ drops }, _ => {
        self.props.rchange(e);
      });
    });
  }

  /**
   * handles on deleting the element.
   *
   * @public
   * @param {e}
   */
  deleteDrops = e => {
    const currentdrops = this.state.drops;
    let newdata = currentdrops.filter(currentdrops => {
      return currentdrops && currentdrops.question_id !== e;
    });
    this.setState(
      {
        drops: newdata
      },
      function() {
        this.props.deteldrops(this.state.drops);
      }
    );
  };

  /**
   * handles on updating the element.
   *
   * @public
   * @param {e}
   */
  updateattrib(lb, e) {
    let olddata = this.state.drops;
    var idx = olddata.findIndex(ele => {
      return ele.vid === e;
    });
    olddata[idx].question = lb;
    this.setState({ olddata }, this.props.ondraglick(this.state.drops));
  }

  /**
   * handles on swapping an element in an array.
   *
   * @public
   * @param {e}
   */
  upArrowFun = e => {
    for (let i = 0; i < this.state.drops.length; i++) {
      if (this.state.drops[i].question_id == e) {
        e = i;
        break;
      }
    }

    var newarray = this.state.drops.slice();

    if (e != 0) {
      let temp = newarray[e - 1];
      newarray[e - 1] = newarray[e];
      newarray[e] = temp;
    }

    this.setState({ drops: newarray }, function() {
      this.props.deteldrops(this.state.drops);
    });
  };

  /**
   * handles on swapping an element in an array.
   *
   * @public
   * @param {e}
   */

  downArrowFun = e => {
    for (let i = 0; i < this.state.drops.length; i++) {
      if (this.state.drops[i].question_id == e) {
        e = i;
        break;
      }
    }

    let newarray = this.state.drops.slice();
    let arrlen = newarray.length - 1;

    if (e != arrlen) {
      let temp = newarray[e + 1];
      newarray[e + 1] = newarray[e];
      newarray[e] = temp;
    }

    this.setState({ drops: newarray }, function() {
      this.props.deteldrops(this.state.drops);
    });
  };

  /**
   * handles on drop actions of the element.
   *
   * @public
   * @param {color}
   * @param {Shape}
   * @param {text}
   * @param {type}
   * @param {dropChange}
   * @param {vid}
   * @param {question}
   * @param {properties}
   * @param {question_id}
   * @param {label}
   */
  handleDrop(
    color,
    shape,
    text,
    type,
    dropChange,
    handler,
    question,
    properties,
    question_id,
    label,
    conditions
  ) {
    const { drops } = this.state;
    const nextDrops = [
      ...drops,
      {
        conditions,
        label,
        type,
        handler,
        properties
      }
    ];

    let qid =
      nextDrops[0] && nextDrops[0].question_id >= 0
        ? nextDrops[0].question_id
        : -1;

    for (var i = 0; i < nextDrops.length; i++) {
      qid =
        nextDrops[i].question_id >= 0 && nextDrops[i].question_id > qid
          ? nextDrops[i].question_id
          : qid;
    }

    qid = qid + 1;
    nextDrops[nextDrops.length - 1].question_id = qid;
    this.state.drops.push(nextDrops[nextDrops.length - 1]);
    this.setState({
      drops: this.state.drops
    });
    //this.props.ondraglick(this.state.drops);
    //this.props.autosave()
  }

  handleDropAppOnly(e) {
    return false;
  }

  /**
   * handles on drop actions of the element.
   *
   * @public
   */
  openevent() {
    this.setState(
      {
        sidebox: this.state.sidebox ? false : true
      },
      this.props.lchange
    );
  }

  moveCard = (dragIndex, hoverIndex) => {
    const { drops } = this.state;
    const dragCard = drops[dragIndex];    
    this.setState(
      update(this.state, {
        drops: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]]
        }
      })
    );
    this.props.ondraglick(this.state.drops);
    this.props.autosave();
  };

  autoSave() {
    this.props.autosave();
  }

  savescore(value){
    this.props.save(value)
  }

  close=(value)=>{
    this.setState({
      mopen:value
    })
  }

  render() {
    let original = this.state.drops;
    
    let filteredinput = original.filter(drop => {
      return drop && drop.type === "input";
    });
    let filteredsingle = original.filter(drop => {
      return drop && drop.type === "Single_Select";
    });
    let filteredmultiple = original.filter(drop => {
      return drop && drop.type === "Multiple_Select";
    });
    let filteredscalequestion = original.filter(drop =>{
      return drop && drop.type ==="Scale_Question";
    });
    let filtereddate = original.filter(drop => {
        return drop && drop.type === "Date_Input";
    });
    let filtereddropdown = original.filter(drop =>{
       return drop && drop.type ==="dropdown";
    });
	let filteredmatrixquestion = original.filter(drop =>{
       return drop && drop.type ==="Matrix_Question";
    });
	let filteredtextareaquestion = original.filter(drop =>{
       return drop && drop.type ==="textarea";
    });
    
    let inputlength = filteredinput.length +1;
    let singlelength = filteredsingle.length +1;
    let multiplelength = filteredmultiple.length +1;
    let scalequestionlength = filteredscalequestion.length +1;
    let dropdownlength = filtereddropdown.length +1;
    let datelength = filtereddate.length +1;
    let matrixquestionlength = filteredmatrixquestion.length +1;
	let textareaquestionlength = filteredtextareaquestion.length +1;



    const { drops, sidebox } = this.state;
    const boardval = sidebox ? "openbox" : "closebox";

    return (
      <div id="board" className={boardval}>
        <div className="new-btn pointer" onClick={() => this.openevent()}>
          <div className="relativepos">
            <p>Add Elements</p>
            <a href="#" className="bdr-ripple-ani-btn rippleff rotator">
              <i class="fa fa-plus"></i>
            </a>
          </div>
        </div>

        <div id="board__sources">
          <div className="new-btn pointerhan" onClick={() => this.openevent()}>
            <div className="relativepos">
              <p>Form Elements</p>
              <a href="#" className="bdr-ripple-ani-btn rippleff rotator">
                {" "}
                <i className="fa fa-times pointer closebtn" />
              </a>
            </div>
          </div>



          <div className="list-froms-wrap">
            
            <Source
              ref="input"
              color="blue"
              icon="fa fa-font"
              text="Text Input"
              question="Type a question"
              onDrop={this.handleDrop}
              type="input"
              labelval={inputlength}
            />
            

            <Source
              ref="date"
              color="blue"
              icon="far fa-calendar-alt"
              text="Date&Time"
              question="Type a question"
              onDrop={this.handleDrop}
              type="Date_Input"
              labelval={datelength}
            />
            
             <Source
              ref="dropdown"
              color="blue"
              icon="fa fa-caret-square-o-down"
              text="Dropdown"
              question="Dropdown question"
              onDrop={this.handleDrop}
              type="dropdown"
              labelval={dropdownlength}
            />
           

            <Source
              ref="SingleSelect"
              color="blue"
              icon="fa fa-check-square"
              text="Single Select"
              onDrop={this.handleDrop}
              type="Single_Select"
              labelval={singlelength}
            />
            
            
            <Source
              ref="MultipleSelect"
              color="MultipleSelect"
              icon="fas fa-check-double"
              text="Multiple Select"
              onDrop={this.handleDrop}
              type="Multiple_Select"
              labelval={multiplelength}
            />
            
            
            <Source
              ref="ScaleQuestion"
              color="blue"
              icon="fa fa-exchange"
              text="Scale/Matrix"
              question="ScaleQuestion"
              onDrop={this.handleDrop}
              type="Scale_Question"
              labelval={scalequestionlength}
            />
			
			<Source
              ref="MatrixQuestion"
              color="blue"
              icon="fa fa-exchange"
              text="Table/Matrix"
              question="ScaleQuestion"
              onDrop={this.handleDrop}
              type="Matrix_Question"
              labelval={matrixquestionlength}
            />
			
			<Source
              ref="textarea"
              color="blue"
              icon="fa fa-font"
              text="Textarea"
              question="Type a question"
              onDrop={this.handleDrop}
              type="textarea"
              labelval={textareaquestionlength}
            />
           
            </div>
           <span onClick={()=> this.setState({mopen:true})}>
             score
           </span>
        </div>



        <div id="board__targets">
          {drops.map((drop, index) =>
            drops[index] ? (
              <Card
                index={index}
                moveCard={this.moveCard}
                color={drop.color}
                key={index}
                question_id={drop.question_id}
                id={drop.id}
                shape={drop.shape}
                text={drop.label}
                type={drop.type}
                attrib={() => this.updateattrib()}
                deleteddrops={e => this.deleteDrops(e)}
                dropstate={this.state.drops}
                rightStatus={drop.rightStatus}
                rightOpen={this.ropen}
                labelprop={drop}
                oldprop={drop}
                test={drop.question}
                oldconditions={this.props.oldconditions}
                autosave={() => this.autoSave()}
                upArrowFunc={e => this.upArrowFun(e)}
                downArrowFunc={e => this.downArrowFun(e)}
                scaleico={this.state.scaleico}
                infoico={this.state.infoico}
                emojis={this.state.emojis}
                gallery={this.state.gallery.images}
              />
            ) : (
              ""
            )
          )}
          <Target shape="circle" />
        </div>

        <div id="board__drops" />
        {this.state.mopen && <Popupp
        drops= {drops}
        score={(e) => this.savescore(e)}
        open={this.state.mopen}
        scorevalue={this.props.score}
        close={this.close}/>}        
      </div>
    );
  }
}
export default DragDropContext(HTML5Backend)(Board);
