import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import Button from "@material-ui/core/Button";

export default class Popupp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible : false,
            textinput:'',
            value:'',
            sectionvalue:'',
            valuesection:'',
            drops:[],
            mapelement:[]
          
        }
    }
    componentWillMount(){
       
	   console.log(this.state);
	    console.log(this.props);
	   console.log('popoup assume'); 
       
	    this.setState({
            drops:this.props.drops,
            visible:this.props.open,
			 value:this.props.scorevalue.value,
			 mapelement:this.props.scorevalue.mapelement
        })
    }

    componentWillReceiveProps (nextprops){
	console.log(this.state);
	   console.log('popoup assume1133'); 
        this.setState({
            drops:nextprops.drops,
            visible:nextprops.open
        })
    }

    openModal() {
        this.setState({
            visible : true
        });
    }

    closeModal() {
        this.setState({
            visible : false
        });
		
        this.props.close(false)
		console.log('pppooooppp');
		console.log(this.state);
    }

    Updatescore=()=>{
        console.log(this.state.value);
		console.log(this.state.mapelement);
		console.log('prakkki');
		//this.state.mapelement.score = this.state.value;
		let score1 = {mapelement:this.state.mapelement, score:this.state.value, value:this.state.value};
		console.log(score1);
        this.props.score(score1)
		//this.props.score(this.state.value)
        this.closeModal()
    }

updatevalue=(e)=>{
this.setState({textinput:e.target.value})
}
update=()=>{
    let temp =this.state.value + "[[" + this.state.valuesection.value + "]]"
	let temp_txt = this.state.valuesection.value;
	let temp_hand = this.state.valuesection.handler;
	this.state.mapelement.push({element:temp_txt, handler:temp_hand});
    this.setState({value:temp})
    //console.log(this.state.value)
}
updateformula =(e) =>{
    this.setState({value:e.target.value})
}

sectionupdate=(e)=>{
 let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;
    let value = e.target.value;
    this.setState({valuesection:{ handler: value, value: label}});
	
    //this.setState({valuesection:e.target.value})
    //this.setState({sectionvalue:e.target.value})
}
    render() {
        
        return (
            <section>
                {/* <input type="button" value="Score" onClick={() => this.openModal()} /> */}
                <Modal 
                    visible={this.state.visible}
                    width="40%"
                    height="60%"
                    effect="fadeInUp"
                    onClickAway={() => this.closeModal()}
                    
                >
                <div style={{height:'100%',width:'100%'}}>
                <div style={{height:'85%',width:'100%'}}>
                    <div>
                        <div class="topbar">
                    <h6 style={{marginTop:"2%"}}>Score</h6>
                    </div>
                        <h6 style={{position:"absolute",marginLeft:"15%",marginTop:"1%"}}>Section</h6>
                        <button style={{position:"absolute",marginTop:"1%",marginLeft:"80%"}} onClick={this.update}>Insert</button>
                        <select style={drop} onChange={this.sectionupdate}>
                            <option>Select section</option>
                            {/* {this.state.drops.map((q,i)=>(
                                q.choicetype ==="Scale Question" ?
                                (q.properties.options.map((x,y)=>(
                                    <option id={i}>{q.label+ " : " + x.label}</option>
                                ))) :
                                <option id={i}>{q.label}</option>
                               
                            ))}
                            */}
                            {this.state.drops.map((e)=>
                                <option value={e.handler} id={e.handler}>{e.label}</option>)}
                        </select>
                        <br/>
                        </div>
                        <div style={{marginLeft:'5%',marginRight:'5%'}}>
                        <h6 style={{marginTop:"5%"}}>Question</h6>
                        <textarea style={inputbox} onChange={this.updatevalue} value={this.state.valuesection.value}></textarea>
                        <h6 style={{marginTop:"5%"}}>Description</h6>
                        <textarea style={inputbox} onChange={this.updateformula} value={this.state.value} name="scorevalue"></textarea>
                    </div>
                    </div>
                    <div style={{height:'15%',float:'right',marginRight:'40%'}}>
                    <Button
                            variant="contained"
                            style={{
                                color: "#fff",
                                backgroundColor: "#074e9e",
                                margin: "10px 0 0px 10px",
                                padding: "5px 16px",
                                fontSize: "12px"
                            }}
                        onClick={() => {this.Updatescore()}}
                        >
                            ok
                </Button>
                        <Button
                            variant="contained"
                            style={{
                                color: "#fff",
                                backgroundColor: "#074e9e",
                                margin: "10px 0 0px 10px",
                                padding: "5px 16px",
                                fontSize: "12px"
                            }}

                        onClick={() => this.closeModal()}
                        >
                            Cancel
                </Button>
                </div>
                    
                    </div>
                </Modal>
            </section>
        );
    }
}
const drop={
    width:"40%",
    marginTop:"1%",
    marginLeft:"30%",
    position:"absolute",
}
const inputbox={
    width:"100%",
    height:"80px",
    // marginLeft:"10%"
}